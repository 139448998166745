.App {
  text-align: center;
  min-height: "fit-content";
  width: 100%;
  /* background-color: #38A836; */
  /* background-image: url(./components/images/codeplant_images/interested.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}
.user-management {
  padding: 10px;
}
.headerBar {
  border-radius: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.p-dock-item {
  margin: 5;
  padding: 0;
}
#dockHolder {
  position: fixed;
  right: 0;
  top: 550px;
  z-index: 10000;
  margin: 0;
  padding: 0;
}
.footer {
  min-height: 500px;
  background-color: white;
}
.p-dataview-content {
  background: none !important;
}
.leftSide {
  width: "20%";
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  padding: 1rem;
  width: 70%;
  margin: auto;
}

.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  height: 150px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.skill-card .pi {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #118c38; /* Customize the icon color */
}

.skill-card span {
  font-size: 1rem;
  font-weight: bold;
}
.flexer {
  display: flex;
  max-width: 400px;
  min-height: 700px;
}
.skill-card-sm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  height: 150px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.skill-card-sm .pi {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #118c38; /* Customize the icon color */
}

.skill-card-sm span {
  font-size: 1rem;
  font-weight: bold;
}

.panelHeader {
  font-weight: bold;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: left;
  padding: 10px;
  background-color: #118c38;
  color: white;
}

.spacer {
  width: 5%;
}

a {
  color: black;
}
.footerPanel {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 15px solid #118c38;
  text-align: center;
  padding: 20px;
  color: black;
  display: flex;
}
.mainPanel {
  width: 90vw;
  /* border: 1px solid #118c38; */
  margin: 5px;
  border-radius: 15px;
}
.mainHolder {
  display: flex;
  width: 100vw;
  background-color: rgb(255, 255, 255, 0.25);
  margin: auto;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 15px;
}
.mainButton {
  color: #01293d;
  margin-right: 10px;
  margin-bottom: 15px;
}
label {
  font-weight: bold;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* Default styles (visible on larger screens) */
.largeIcon,
.largeImage {
  display: block;
}
.shads {
  -webkit-box-shadow: 4px 5px 7px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 5px 7px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 5px 7px 2px rgba(0, 0, 0, 0.75);
}
.modalWidth {
  width: 50%;
}

.formFieldsWidth {
  width: 50%;
}

.sliderWrapper {
  margin-top: 100px;
}

.largeImage {
  width: 100%;
  text-align: center;
}

.slides {
  position: relative;
}

.slides img {
  width: 100%;
}
.footerMobile {
  display: none;
}
.slides .overlay-text {
  position: absolute;
  transform: translate(-50%, -50%);
  color: #118c38;
  font-size: 40px;
  font-weight: bold;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); */
  /* pointer-events: none;  */
}

.pitchContentMobile {
  display: none;
}

#desktopSite {
  display: inline;
}
#mobileSite {
  display: none;
}

/* Hide elements on small screens */
@media screen and (max-width: 600px) {
  .largeIcon,
  .largeImage {
    display: none;
  }
  #desktopSite {
    display: none;
  }
  #mobileSite {
    display: inline;
  }
  .modalWidth {
    width: 100%;
  }
  .formFieldsWidth {
    width: 100%;
  }
  .leftSide {
    display: none;
  }
  .mainPanel {
    width: 100vw;
  }
  .footerPanel {
    display: none;
  }
  .sliderWrapper {
    display: none;
  }
  .footerMobile {
    display: inline;
  }
  .dock-window {
    display: none;
  }
  .pitchContent {
    display: none;
  }

  .pitchContentMobile {
    display: inline;
  }

  .mainHolder {
    width: 100vw;
    margin-top: 70px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.timeline {
  position: relative;
  padding: 21px 0px 10px;
  list-style: none;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #5cb85c;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 50px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}
.rounded {
  border-radius: 15px;
}
.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  color: black;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.timeline-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.bg-green {
  background-color: #4caf50;
  color: white;
}
.bg-blue {
  background-color: #2196f3;
  color: white;
}
.bg-purple {
  background-color: #9c27b0;
  color: white;
}
.bg-red {
  background-color: #f44336;
  color: white;
}
.bg-orange {
  background-color: #ff9800;
  color: white;
}
.bg-teal {
  background-color: #009688;
  color: white;
}
.bg-yellow {
  background-color: #ffeb3b;
  color: black;
}
